import styled from "styled-components";
import Map from "./Map";
import Table from "./RecentEventsTable";
import { MdOutlineAppRegistration } from "react-icons/md";
import { LuSmartphoneNfc } from "react-icons/lu";
import { IoGameControllerOutline } from "react-icons/io5";
import { GiTrail } from "react-icons/gi";
import { useEffect, useState } from "react";
import BarLoader from "react-spinners/BarLoader";
import config from "../config";

interface Event {
  message: string;
  deviationSpeed: number;
  deviationLane: number;
  timestamp: string;
  vehicles: {
    leadingVehicleVin: string;
    followingVehicleVin: string;
  };
}

interface InfoValueProps {
  isLoading: boolean;
  error: string | null;
  data: any;
  color: string;
}

const StyledDashboard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto 46rem;
`;

const StyledBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 81%;
  height: auto;
  display: grid;
  grid-template-columns: 4rem 1fr;
  grid-template-rows: 2rem 2rem;
  column-gap: 1rem;
  row-gap: 0;
  padding: 1rem 1rem 1rem 1.5rem;
  margin-top: 0.2rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  background-color: var(--color-white);
  border-radius: 0.5rem;
  border: 0.25px solid var(--color-grey-border-light);
`;

const StyledIcon = styled.div`
  & svg {
    width: 2.2rem;
    height: 2.2rem;
    color: ${(props) =>
      props.color === "var(--color-pink-background)"
        ? "var(--color-pink-border)"
        : props.color === "var(--color-orange-background)"
        ? "var(--color-orange-border)"
        : props.color === "var(--color-blue-background)"
        ? "var(--color-blue-border)"
        : "var(--color-green-border)"};
    border-radius: 50%;
    border: 0.25px solid
      ${(props) =>
        props.color === "var(--color-pink-background)"
          ? "var(--color-pink-border)"
          : props.color === "var(--color-orange-background)"
          ? "var(--color-orange-border)"
          : props.color === "var(--color-blue-background)"
          ? "var(--color-blue-border)"
          : "var(--color-green-border)"};
    background-size: 1cm;
    background-position: "center";
    background-color: ${(props) => props.color};
    padding: 0.9rem;
  }
`;

const StyledInfoTitle = styled.h4`
  grid-row: 1;
  grid-column: 2;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  font-family: var(--font-family);
  font-weight: 500;
  color: var(--color-black);
  margin-top: auto;
`;

const StyledInfoValue = styled.p<{ isError?: boolean }>`
  grid-row: 1;
  grid-column: 2;
  font-family: var(--font-family);
  font-size: ${(props) => (props.isError ? "0.85rem" : "1.7rem")};
  font-weight: ${(props) => (props.isError ? "0" : "500")};
  margin-top: ${(props) => (props.isError ? "1.8rem" : "1.8rem")};
`;

const InfoValue: React.FC<InfoValueProps> = ({
  isLoading,
  error,
  data,
  color,
}) => (
  <StyledInfoValue isError={!!error}>
    {isLoading ? (
      <div style={{ marginTop: "0.8rem" }}>
        <BarLoader color={color} speedMultiplier={2} loading={true} />
      </div>
    ) : error ? (
      error
    ) : (
      data.length
    )}
  </StyledInfoValue>
);

const { INVENTORY_ENDPOINT, CONTROL_ENDPOINT } = config;

function DashboardLayout() {
  const [vehicles, setVehicles] = useState<any[]>([]);
  const [leadingVehicles, setLeadingVehicles] = useState<string[]>([]);
  const [followingVehicles, setFollowingVehicles] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchVehicles = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${INVENTORY_ENDPOINT}`);
        const data = await response.json();
        setVehicles(data);
      } catch (error) {
        setError("Could not be loaded - Try again later!");
      } finally {
        setIsLoading(false);
      }
    };
    fetchVehicles();

    const fetchInterval = setInterval(fetchVehicles, 10000);
    return () => clearInterval(fetchInterval);
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${CONTROL_ENDPOINT}`);
        var data = await response.json();

        const leadingVehicleVins = data.map(
          (event: Event) => event.vehicles.leadingVehicleVin
        );
        const followingVehicleVins = data.map(
          (event: Event) => event.vehicles.followingVehicleVin
        );
        setLeadingVehicles(Array.from(new Set(leadingVehicleVins)));
        setFollowingVehicles(Array.from(new Set(followingVehicleVins)));
      } catch (error) {
        console.log(error);
        setError("Could not be loaded - Try again later!");
      } finally {
        setIsLoading(false);
      }
    };
    fetchEvents();

    const fetchInterval = setInterval(fetchEvents, 10000);
    return () => clearInterval(fetchInterval);
  }, []);

  return (
    <StyledDashboard>
      <StyledBox>
        <StyledIcon color="var(--color-orange-background)">
          <MdOutlineAppRegistration />
        </StyledIcon>
        <StyledInfoTitle>Vehicles</StyledInfoTitle>
        <InfoValue
          isLoading={isLoading}
          color={"var(--color-orange-background)"}
          error={error}
          data={vehicles}
        />
      </StyledBox>
      <StyledBox>
        <StyledIcon color="var(--color-blue-background)">
          {" "}
          <LuSmartphoneNfc />{" "}
        </StyledIcon>
        <StyledInfoTitle>Autonomous</StyledInfoTitle>
        <InfoValue
          isLoading={isLoading}
          color={"var(--color-blue-background)"}
          error={error}
          data={vehicles.filter(
            (vehicle) => vehicle.vehicleType === "autonomous"
          )}
        />
      </StyledBox>
      <StyledBox>
        <StyledIcon color="var(--color-green-background)">
          {" "}
          <IoGameControllerOutline />{" "}
        </StyledIcon>
        <StyledInfoTitle>Leading</StyledInfoTitle>
        <InfoValue
          isLoading={isLoading}
          color={"var(--color-green-background)"}
          error={error}
          data={leadingVehicles}
        />
      </StyledBox>
      <StyledBox>
        <StyledIcon color="var(--color-pink-background)">
          {" "}
          <GiTrail />{" "}
        </StyledIcon>
        <StyledInfoTitle>Following</StyledInfoTitle>
        <InfoValue
          isLoading={isLoading}
          color={"var(--color-pink-background)"}
          error={error}
          data={followingVehicles}
        />
      </StyledBox>
      <Table />
      <Map />
    </StyledDashboard>
  );
}

export default DashboardLayout;
