import { useState } from "react";
import styled from "styled-components";

const StyledForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 2rem auto;
  padding: 2.5rem 3rem;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-light);
  border-radius: 5px;
`;

const StyledInput = styled.input`
  padding: 1rem 1.5rem;
  border-radius: 5px;
  font-size: 0.9rem;
  font-family: var(--font-family);
  color: var(--color-black);
  border: 1px solid var(--color-row-border);
  background-color: var(--color-white);
`;

const StyledRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column: 1 / span 2;
  gap: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  margin-right: 10rem;
  margin-left: 4rem;
  &:not(:last-child) {
    border-bottom: 1px solid var(--color-row-border);
  }
`;

const StyledDescription = styled.label`
  font-size: 1rem;
  font-family: var(--font-family);
  margin: 1rem 0 0 1rem;
`;

const StyledButton = styled.button`
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid var(--color-blue-border);
  font-size: 1rem;
  font-family: var(--font-family);
  font-weight: 600;
  color: var(--color-blue-border);
  background-color: var(--color-blue-background);
  margin: 0 10rem;
  grid-column: 2 / span 1;
`;

function RegisterVehicles() {
  const [values, setValues] = useState({
    vin: "",
    oem: "",
    modelType: "",
    vehicleType: "",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <StyledForm>
      <StyledRow>
        <StyledDescription htmlFor="vin">VIN</StyledDescription>
        <StyledInput
          id="vin"
          name="vin"
          value={values.vin}
          onChange={handleChange}
          type="text"
          placeholder="Enter the VIN of your vehicle"
          required
        />
      </StyledRow>
      <StyledRow>
        <StyledDescription htmlFor="oem">OEM</StyledDescription>
        <StyledInput
          id="oem"
          name="oem"
          type="text"
          value={values.oem}
          onChange={handleChange}
          placeholder="Enter the OEM of your vehicle"
          required
        />
      </StyledRow>
      <StyledRow>
        <StyledDescription htmlFor="modelType">MODEL TYPE</StyledDescription>
        <StyledInput
          id="modelType"
          name="modelType"
          type="text"
          value={values.modelType}
          onChange={handleChange}
          placeholder="Enter the Model Type of your vehicle"
          required
        />
      </StyledRow>
      <StyledRow>
        <StyledDescription htmlFor="vehicleType">
          VEHICLE TYPE
        </StyledDescription>
        <StyledInput
          id="vehicleType"
          name="vehicleType"
          type="text"
          value={values.vehicleType}
          onChange={handleChange}
          placeholder="Enter the Vehicle Type of your vehicle"
          required
        />
      </StyledRow>
      <StyledButton type="submit">Register Vehicle</StyledButton>
    </StyledForm>
  );
}

export default RegisterVehicles;
