import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./ui/Layout";
import Dashboard from "./pages/Dashboard";
import VehicleData from "./pages/VehicleData";
import RegisterVehicle from "./pages/RegisterVehicle";
import EventsTable from "./pages/EventsTable";
import GlobalStyles from "./styles/GlobalStyles";

function App() {
  return (
    <>
      <GlobalStyles />
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route index element={<Navigate replace to="dashboard" />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="vehicles" element={<VehicleData />} />
            <Route path="events" element={<EventsTable />} />
            <Route path="registration" element={<RegisterVehicle />} />
            <Route path="*" element={<div>Hallo!!</div>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
