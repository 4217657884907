import styled from "styled-components";
import EventsTableRow from "./EventsTableRow";
import { Client } from "@stomp/stompjs";
import { useState, useEffect } from "react";
import BarLoader from "react-spinners/BarLoader";
import config from "../config";

const StyledTable = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 2;
  padding: 1rem;
  margin: 0 1rem 0 0.5rem;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-light);
  border-radius: 5px;
  align-items: center;

  @media (max-width: 1500px) {
    flex-direction: column;
    height: auto;
    margin-right: 0;
  }

  @media (min-width: 2000px) {
    margin-right: 3.3rem;
  }
`;

const StyledTableHeader = styled.thead`
  display: grid;
  justify-content: center;
  grid-template-columns: 4rem 3.5rem 5rem 5rem 7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-black);
  gap: 3rem;
  border-bottom: 2px solid var(--color-row-border);

  @media (max-width: 1500px) {
    flex-direction: column;
    grid-template-columns: 2rem 2rem 2rem 2rem 2rem;
  }
`;

const StyledTableHeaderCell = styled.th`
  text-align: left;
  letter-spacing: 0.1px;
  font-family: var(--font-family);
  font-size: 0.9rem;
  padding: 0.5rem 0;
`;

const StyledList = styled.ul`
  overflow: scroll;
  overflow-x: hidden;

  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-right: 2.5rem;
`;

const MAX_ROWS = 11;
const { BROKER_URL, RMQ_USER, RMQ_PASS, RMQ_VHOST, RMQ_TABLE_QUEUE } = config;

function RecentEventsTable() {
  const [events, setEvents] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    var client = new Client({
      brokerURL: BROKER_URL,
      connectHeaders: {
        login: RMQ_USER as string,
        passcode: RMQ_PASS as string,
        host: RMQ_VHOST as string,
      },
      debug: function (string) {
        console.log(string);
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
    });

    client.onConnect = () => {
      client.subscribe(`/queue/${RMQ_TABLE_QUEUE}`, (message) => {
        console.log("Received message: " + message.body);
        const eventData = JSON.parse(message.body);
        setIsLoading(false);

        setEvents((prevEvents) => {
          const tmpEvents = [eventData, ...prevEvents];
          return tmpEvents.slice(0, MAX_ROWS);
        });
      });
    };

    client.onStompError = (frame) => {
      console.log("Broker reported error: " + frame.headers["message"]);
    };

    client.onDisconnect = () => {
      console.log("Connection Terminated");
    };

    client.onWebSocketClose = (closeEvent) => {
      console.log("Websocket Terminated!");
      console.log(`Reason: ${closeEvent.reason}`);
      setIsLoading(false);
      setError("Could not be loaded - Try again later!");
    };

    client.activate();
  }, []);

  return (
    <StyledTable>
      <StyledTableHeader>
        <StyledTableHeaderCell>TIMESTAMP</StyledTableHeaderCell>
        <StyledTableHeaderCell>EVENT</StyledTableHeaderCell>
        <StyledTableHeaderCell>VIN LV</StyledTableHeaderCell>
        <StyledTableHeaderCell>VIN FV</StyledTableHeaderCell>
        <StyledTableHeaderCell>DEVIATION</StyledTableHeaderCell>
      </StyledTableHeader>
      {isLoading ? (
        <div style={{ marginTop: "10rem" }}>
          <BarLoader
            color={"var(--color-green-background)"}
            speedMultiplier={2}
            loading={true}
          />
        </div>
      ) : error ? (
        error
      ) : (
        <StyledList>
          {events.map((event, index) => (
            <EventsTableRow key={index} event={event} eventsPage={0} />
          ))}
        </StyledList>
      )}
    </StyledTable>
  );
}

export default RecentEventsTable;
