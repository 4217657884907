import styled from "styled-components";
import SidebarMenu from "./SidebarMenu";

const StyledSidebar = styled.aside`
  background-color: #ffffff;
  padding: 1.5rem 1rem;
  grid-row: 1 / -1;
  border-right: 0.5px solid #f2f2f2;
  width: 285px;
  text-align: center;
  padding-bottom: 1.5rem;
`;

const Image = styled.img`
  height: 7.5rem;
  width: auto;
`;

const Line = styled.div`
  width: 80%;
  height: 2.5px;
  background-color: var(--color-black);
  margin-top: -1.1rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 70%;
`;

function Sidebar() {
  return (
    <StyledSidebar>
      <Image src="/smart-car.png" />
      <Line />
      <SidebarMenu />
    </StyledSidebar>
  );
}

export default Sidebar;
