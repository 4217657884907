import DashboardLayout from "../ui/DashboardLayout";

function Dashboard() {
  return (
    <>
      <DashboardLayout />
    </>
  );
}

export default Dashboard;
