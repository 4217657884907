import styled from "styled-components";

interface Event {
  message: string;
  deviationSpeed: number;
  deviationLane: number;
  timestamp: string;
  vehicles: {
    leadingVehicleVin: string;
    followingVehicleVin: string;
  };
}

const StyledRow = styled.li<{ eventsPage: number }>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.eventsPage === 0
      ? "4rem 3.5rem 5rem 5rem 7rem"
      : "10rem 10rem 10rem 10rem 20rem"};
  gap: 3rem;
  font-family: var(--font-family);
  font-size: 1rem;
  align-items: center;
  border-bottom: 1px solid var(--color-row-border);
  padding: 0.5rem 0;

  @media (max-width: 1500px) {
    flex-direction: column;
    grid-template-columns: 2rem 2rem 2rem 2rem 2rem;
  }
`;

const StyledEventType = styled.span<{ type: string }>`
  padding: 0.3rem 0.8rem;
  border-radius: 7px;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-family: var(--font-family);
  font-weight: 600;
  color: var(--color-${(props) => props.type}-border);
  background-color: var(--color-${(props) => props.type}-background);
  border: 0.5px solid var(--color-${(props) => props.type}-border);
`;

const StyledTd = styled.td`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

function EventsTableRow({
  event,
  eventsPage,
}: {
  event: Event;
  eventsPage: number;
}) {
  const { message, deviationSpeed, deviationLane, timestamp, vehicles } = event;
  const { leadingVehicleVin, followingVehicleVin } = vehicles;

  return (
    <StyledRow eventsPage={eventsPage}>
      <StyledTd>{timestamp}</StyledTd>
      <StyledTd style={{ marginTop: "0.15rem", marginBottom: "0.15rem" }}>
        {message.includes("started") && (
          <StyledEventType type="green">Started</StyledEventType>
        )}
        {message.includes("stopped") && (
          <StyledEventType type="orange">Stopped</StyledEventType>
        )}
        {message.includes("Alarm") && (
          <StyledEventType type="red">Alarm</StyledEventType>
        )}
      </StyledTd>
      <StyledTd>{leadingVehicleVin}</StyledTd>
      <StyledTd>{followingVehicleVin}</StyledTd>
      <StyledTd
        style={{
          whiteSpace: "normal",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >{`Speed: ${deviationSpeed} km/h,Lane: ${deviationLane}`}</StyledTd>
    </StyledRow>
  );
}

export default EventsTableRow;
