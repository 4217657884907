import styled from "styled-components";
import { useState, useEffect } from "react";
import Pagination from "../ui/Pagination";
import { MouseEvent } from "react";
import EventsTableRow from "../ui/EventsTableRow";
import config from "../config";
import BarLoader from "react-spinners/BarLoader";

interface Event {
  message: string;
  deviationSpeed: number;
  deviationLane: number;
  timestamp: string;
  vehicles: {
    leadingVehicleVin: string;
    followingVehicleVin: string;
  };
}

const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 2rem;
  padding-top: 1rem;
  overflow: hidden;
  font-size: 1.5rem;
  font-family: var(--font-family);
  border-radius: 5px;
  background-color: var(--color-white);
`;

const StyledTableHeader = styled.thead`
  display: grid;
  grid-template-columns: 10rem 10rem 10rem 10rem 20rem;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-black);
  gap: 3rem;
  border-bottom: 2px solid var(--color-row-border);
  margin: 0 2.5rem;

  @media (max-width: 1500px) {
    flex-direction: column;
    grid-template-columns: 2rem 2rem 2rem 2rem 2rem;
  }
`;

const StyledTableHeaderCell = styled.th`
  text-align: left;
  letter-spacing: 0.1px;
  font-family: var(--font-family);
  font-size: 1rem;
  padding: 0.5rem 0;
`;

const StyledList = styled.ul`
  overflow: scroll;
  overflow-x: hidden;

  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-right: 2.5rem;
`;

const { CONTROL_ENDPOINT } = config;
const ROWS_PER_PAGE = 15;

function RecentEventsTable() {
  const [currentPage, setCurrentPage] = useState(1);
  const [rows, setRows] = useState<Event[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const changePage = (
    event: MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => {
    setCurrentPage(newPage);
  };

  const paginatedRows = rows.slice(
    (currentPage - 1) * ROWS_PER_PAGE,
    currentPage * ROWS_PER_PAGE
  );

  useEffect(() => {
    const fetchEvents = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${CONTROL_ENDPOINT}`);
        var data = await response.json();

        data = data.sort((a: Event, b: Event) => {
          return Number(b.timestamp) - Number(a.timestamp);
        });

        setRows(data);
      } catch (error) {
        console.log(error);
        setError("Could not be loaded - Try again later!");
      } finally {
        setIsLoading(false);
      }
    };
    fetchEvents();

    const fetchInterval = setInterval(fetchEvents, 20000);
    return () => clearInterval(fetchInterval);
  }, []);

  return (
    <div>
      <StyledTable>
        <StyledTableHeader>
          <StyledTableHeaderCell>TIMESTAMP</StyledTableHeaderCell>
          <StyledTableHeaderCell>EVENT</StyledTableHeaderCell>
          <StyledTableHeaderCell>VIN LV</StyledTableHeaderCell>
          <StyledTableHeaderCell>VIN FV</StyledTableHeaderCell>
          <StyledTableHeaderCell>DEVIATION</StyledTableHeaderCell>
        </StyledTableHeader>
        <StyledList>
          {isLoading ? (
            <div style={{ marginTop: "0.8rem" }}>
              <BarLoader
                color={"var(--color-green-background)"}
                speedMultiplier={2}
                loading={true}
              />
            </div>
          ) : error ? (
            <div style={{ fontSize: "1rem" }}> {error} </div>
          ) : (
            paginatedRows.map((row, index) => (
              <EventsTableRow key={index} event={row} eventsPage={1} />
            ))
          )}
        </StyledList>
      </StyledTable>
      <Pagination
        count={Math.ceil(rows.length / ROWS_PER_PAGE)}
        page={currentPage}
        onChange={changePage}
      />
    </div>
  );
}

export default RecentEventsTable;
