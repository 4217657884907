import { MouseEvent } from "react";
import styled, { css } from "styled-components";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

interface PaginationProps {
  count: number;
  page: number;
  onChange: (event: MouseEvent<HTMLButtonElement>, page: number) => void;
}

const StyledPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.9rem;
`;

const StyledButton = styled.button<{ isActive?: boolean }>`
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  font-size: 1rem;
  font-family: var(--font-family);
  text-align: center;
  color: #151515;
  margin: 0;
  padding: 0;

  &:not(:disabled):hover {
    background-color: #f2f2f2;
  }

  ${(props) =>
    props.isActive &&
    css`
      background-color: #e3f6ce;
      border: 0.5px solid #0b610b;
      color: #151515;
    `}

  &:disabled {
    ${(props) =>
      props.isActive
        ? css`
            color: #151515;
          `
        : css`
            color: #bdbdbd;
          `}
  }
`;

const StyledIcon = styled.div`
  position: relative;
  top: 0.12rem;
`;

const StyledPaginationBar = styled.div`
  display: inline-flex;
  border-radius: 5px;
  background-color: var(--color-grey-light);
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 0.45rem;
  padding-right: 0.45rem;
`;

function Pagination({ count, page, onChange }: PaginationProps) {
  const pages = Array.from({ length: count }, (_, i) => i + 1);

  return (
    <StyledPagination>
      <StyledPaginationBar>
        <StyledButton onClick={(e) => onChange(e, 1)} disabled={page === 1}>
          <StyledIcon>
            <MdKeyboardDoubleArrowLeft size={20} />
          </StyledIcon>
        </StyledButton>
        <StyledButton
          onClick={(e) => onChange(e, page - 1)}
          disabled={page === 1}
        >
          <StyledIcon>
            <MdKeyboardArrowLeft size={20} />
          </StyledIcon>
        </StyledButton>
        {pages.map((p) => (
          <StyledButton
            key={p}
            onClick={(e) => onChange(e, p)}
            disabled={p === page}
            isActive={p === page}
          >
            {p}
          </StyledButton>
        ))}
        <StyledButton
          onClick={(e) => onChange(e, page + 1)}
          disabled={page === count}
        >
          <StyledIcon>
            <MdKeyboardArrowRight size={20} />
          </StyledIcon>
        </StyledButton>
        <StyledButton
          onClick={(e) => onChange(e, count)}
          disabled={page === count}
        >
          <StyledIcon>
            <MdKeyboardDoubleArrowRight size={20} />
          </StyledIcon>
        </StyledButton>
      </StyledPaginationBar>
    </StyledPagination>
  );
}

export default Pagination;
