import styled from "styled-components";

interface Vehicle {
  vin: string;
  oem: string;
  modelType: string;
  vehicleType: string;
}

const StyledRow = styled.li`
  display: grid;
  grid-template-columns: 15rem 15rem 15rem 15rem;
  gap: 3rem;
  font-family: var(--font-family);
  font-size: 1rem;
  align-items: center;
  border-bottom: 1px solid var(--color-row-border);
  padding: 0.5rem 0;

  @media (max-width: 1500px) {
    flex-direction: column;
    grid-template-columns: 2rem 2rem 2rem 2rem;
  }
`;

const StyledEventType = styled.span<{ type: string }>`
  padding: 0.3rem 0.8rem;
  border-radius: 7px;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-family: var(--font-family);
  font-weight: 600;
  color: var(--color-${(props) => props.type}-border);
  background-color: var(--color-${(props) => props.type}-background);
  border: 0.5px solid var(--color-${(props) => props.type}-border);
`;

function VehicleTableRow({ vehicle }: { vehicle: Vehicle }) {
  const { vin, oem, modelType, vehicleType } = vehicle;
  return (
    <StyledRow>
      <td>{vin}</td>
      <td>{oem}</td>
      <td>{modelType}</td>
      <td style={{ marginTop: "0.15rem", marginBottom: "0.15rem" }}>
        {vehicleType.toLowerCase() === "autonomous" && (
          <StyledEventType type="orange">Autonomous</StyledEventType>
        )}
        {vehicleType.toLowerCase() === "non_autonomous" && (
          <StyledEventType type="blue">Non-autonomous</StyledEventType>
        )}
      </td>
    </StyledRow>
  );
}

export default VehicleTableRow;
