import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    :root {
        --color-green-border: #04B404;
        --color-green-background: #E0F8E0;

        --color-pink-background: #F8E0EC;
        --color-pink-border: #FE2E9A;

        --color-blue-background: #CED8F6;
        --color-blue-border: #2E2EFE;

        --color-orange-background: #F6E3CE;
        --color-orange-border: #FF8000;

        --color-red-background: #F6CECE;
        --color-red-border: #DF0101;

        --color-black: #151515;

        --color-white: #FFFFFF;
        
        --color-grey-border-light: #F2F2F2;
        --color-grey-light: #FAFAFA;

        --color-row-border: #BDBDBD;

        --font-family: "Playfair Display";
    }   

`;

export default GlobalStyles;
