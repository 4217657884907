import styled from "styled-components";
import { useEffect, useState } from "react";
import Pagination from "../ui/Pagination";
import { MouseEvent } from "react";
import VehicleTableRow from "../ui/VehicleTableRow";
import BarLoader from "react-spinners/BarLoader";
import config from "../config";

interface Vehicle {
  vin: string;
  oem: string;
  modelType: string;
  vehicleType: string;
}

const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 2rem;
  padding-top: 1rem;
  overflow: hidden;
  font-size: 1.5rem;
  font-family: var(--font-family);
  border-radius: 5px;
  background-color: var(--color-white);
`;

const StyledTableHeader = styled.thead`
  display: grid;
  grid-template-columns: 15rem 15rem 15rem 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-black);
  gap: 3rem;
  border-bottom: 2px solid var(--color-row-border);
  margin: 0 2.5rem;

  @media (max-width: 1500px) {
    flex-direction: column;
    grid-template-columns: 2rem 2rem 2rem 2rem;
  }
`;

const StyledTableHeaderCell = styled.th`
  text-align: left;
  letter-spacing: 0.1px;
  font-family: var(--font-family);
  font-size: 1rem;
  padding: 0.5rem 0;
`;

const StyledList = styled.ul`
  overflow: scroll;
  overflow-x: hidden;

  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-right: 2.5rem;
`;

const { INVENTORY_ENDPOINT } = config;
const ROWS_PER_PAGE = 15;

function RecentEventsTable() {
  const [currentPage, setCurrentPage] = useState(1);
  const [rows, setRows] = useState<Vehicle[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const changePage = (
    event: MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => {
    setCurrentPage(newPage);
  };

  const paginatedRows = rows.slice(
    (currentPage - 1) * ROWS_PER_PAGE,
    currentPage * ROWS_PER_PAGE
  );

  useEffect(() => {
    const fetchVehicles = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${INVENTORY_ENDPOINT}`);
        const data = await response.json();
        setRows(data);
      } catch (error) {
        console.log(error);
        setError("Could not be loaded - Try again later!");
      } finally {
        setIsLoading(false);
      }
    };
    fetchVehicles();

    const fetchInterval = setInterval(fetchVehicles, 20000);
    return () => clearInterval(fetchInterval);
  }, []);

  return (
    <div>
      <StyledTable>
        <StyledTableHeader>
          <StyledTableHeaderCell>VIN</StyledTableHeaderCell>
          <StyledTableHeaderCell>OEM</StyledTableHeaderCell>
          <StyledTableHeaderCell>MODEL TYPE</StyledTableHeaderCell>
          <StyledTableHeaderCell>VEHICLE TYPE</StyledTableHeaderCell>
        </StyledTableHeader>
        <StyledList>
          {isLoading ? (
            <div style={{ marginTop: "0.8rem" }}>
              <BarLoader
                color={"var(--color-green-background)"}
                speedMultiplier={2}
                loading={true}
              />
            </div>
          ) : error ? (
            <div style={{ fontSize: "1rem" }}> {error} </div>
          ) : (
            paginatedRows.map((row) => (
              <VehicleTableRow key={row.vin} vehicle={row} />
            ))
          )}
        </StyledList>
      </StyledTable>
      <Pagination
        count={Math.ceil(rows.length / ROWS_PER_PAGE)}
        page={currentPage}
        onChange={changePage}
      />
    </div>
  );
}

export default RecentEventsTable;
