import { MapContainer, TileLayer, useMap } from "react-leaflet";
import "leaflet-rotatedmarker";
import L, { LatLngExpression, Icon } from "leaflet";
import ReactDOMServer from "react-dom/server";
import { useState, useEffect } from "react";
import styled from "styled-components";
import yellowCarIcon from "../images/car_yellow.png";
import blueCarIcon from "../images/car_blue.png";
import { Client } from "@stomp/stompjs";
import config from "../config";

interface RotatedMarkerProps {
  position: LatLngExpression;
  icon: Icon;
  rotationAngle: number;
  popupContent: string;
}

const StyledMap = styled.div`
  display: flex;
  padding: 1.5rem 1.5rem;
  margin: 0 1rem 0 0.5rem;
  grid-column: 3 / span 2;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-light);
  border-radius: 5px;

  @media (max-width: 1500px) {
    flex-direction: column;
    height: auto;
    margin-right: 0;
  }

  @media (min-width: 2000px) {
    margin-right: 3.3rem;
  }
`;

const { BROKER_URL, RMQ_USER, RMQ_PASS, RMQ_VHOST, RMQ_MAP_QUEUE } = config;

function RotatedMarker({
  position,
  icon,
  rotationAngle,
  popupContent,
}: RotatedMarkerProps) {
  const map = useMap();

  useEffect(() => {
    const marker = new L.Marker(position, {
      icon,
      rotationAngle,
      rotationOrigin: "center center",
    });

    marker.addTo(map);

    marker.bindPopup(popupContent);

    marker.on("mouseover", () => {
      marker.openPopup();
    });

    marker.on("mouseout", () => {
      marker.closePopup();
    });

    return () => {
      map.removeLayer(marker);
    };
  }, [map, position, icon, rotationAngle, popupContent]);

  return null;
}

// function calculateAngle(
//   previousPosition: [number, number] | undefined,
//   currentPosition: [number, number] | undefined
// ) {
//   if (!previousPosition || !currentPosition) {
//     return 0;
//   }

//   const dy = currentPosition[0] - previousPosition[0];
//   const dx = currentPosition[1] - previousPosition[1];
//   var angle = Math.atan2(dy, dx) * (180 / Math.PI);
//   if (angle < 0) {
//     angle += 360;
//   }
//   return angle;
// }

function Map() {
  const [vehicles, setVehicles] = useState<any[]>([]);

  useEffect(() => {
    var client = new Client({
      brokerURL: BROKER_URL,
      connectHeaders: {
        login: RMQ_USER as string,
        passcode: RMQ_PASS as string,
        host: RMQ_VHOST as string,
      },
      debug: function (string) {
        console.log(string);
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
    });

    client.onConnect = () => {
      client.subscribe(`/queue/${RMQ_MAP_QUEUE}`, (message) => {
        console.log("Received message: " + message.body);
        const vehicleData = JSON.parse(message.body);

        setVehicles((prevVehicles) => ({
          ...prevVehicles,
          [vehicleData.actor.vin]: vehicleData,
        }));
      });
    };

    client.onStompError = (frame) => {
      console.log("Broker reported error: " + frame.headers["message"]);
      console.log("Additional details: " + frame.body);
    };

    client.onDisconnect = () => {
      console.log("Connection Terminated");
    };

    client.onWebSocketClose = (closeEvent) => {
      console.log("Websocket Terminated!!");
      console.log(`Reason: ${closeEvent.reason}`);
    };

    client.activate();
  }, []);

  return (
    <StyledMap>
      <MapContainer
        style={{ height: "100%", width: "100%" }}
        center={[-32.465685, 123.832566]}
        zoom={7}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {Object.values(vehicles).map((vehicle) => {
          const carIcon = L.icon({
            iconUrl:
              vehicle.actor.vehicleType === "autonomous"
                ? yellowCarIcon
                : blueCarIcon,
            iconSize: [35, 35],
            popupAnchor: [0, -14],
          });

          const popupContent = ReactDOMServer.renderToString(
            <>
              <strong>{vehicle.actor.vin}</strong>
              <br />
              Speed: {vehicle.action.current.speed} km/h
              <br />
              Lane: {vehicle.action.current.lane}
            </>
          );

          return (
            <RotatedMarker
              key={vehicle.actor.vin}
              position={[vehicle.coordinates[1], vehicle.coordinates[0]]}
              icon={carIcon}
              rotationAngle={0}
              popupContent={popupContent}
            />
          );
        })}
      </MapContainer>
    </StyledMap>
  );
}

export default Map;
