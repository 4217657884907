import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { IoCarSportSharp } from "react-icons/io5";
import { IoPieChartOutline } from "react-icons/io5";
import { TbTimelineEventPlus } from "react-icons/tb";
import { MdOutlineAppRegistration } from "react-icons/md";

const StyledUl = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  list-style: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  & > :first-child {
    margin-top: 2rem;
  }
`;

const StyledNavLink = styled(NavLink)`
  &:link,
  &:visited {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #585757;
    font-size: 1.1rem;
    font-weight: 525;
    font-family: var(--font-family);
    padding: 1.25rem 2.2rem;
    text-decoration-line: none;
  }
  &:hover {
    background-color: #fafafa;
    border-radius: 5px;
  }
  &:active,
  &.active:link,
  &.active:visited {
    color: #151515;
    background-color: #fafafa;
    border-radius: 5px;
    text-decoration-line: none;
  }
  & svg {
    width: auto;
    height: auto;
    color: #585757;
    transition: all 0.3s;
  }
  &:hover svg,
  &:active svg,
  &.active:link svg,
  &.active:visited svg {
    color: #04b404;
  }
`;

function SidebarMenu() {
  return (
    <nav>
      <StyledUl>
        <li>
          <StyledNavLink to="/dashboard">
            <IoPieChartOutline />
            <span>Dashboard</span>
          </StyledNavLink>
        </li>
        <li>
          <StyledNavLink to="/vehicles">
            <IoCarSportSharp />
            <span>Vehicle Data</span>
          </StyledNavLink>
        </li>
        <li>
          <StyledNavLink to="/events">
            <TbTimelineEventPlus />
            <span>Event History</span>
          </StyledNavLink>
        </li>
        <li>
          <StyledNavLink to="/registration">
            <MdOutlineAppRegistration />
            <span>Registration</span>
          </StyledNavLink>
        </li>
      </StyledUl>
    </nav>
  );
}

export default SidebarMenu;
