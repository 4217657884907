const config = {
  BROKER_URL: process.env.REACT_APP_BROKER_URL,
  RMQ_USER: process.env.REACT_APP_RMQ_USERNAME,
  RMQ_PASS: process.env.REACT_APP_RMQ_PASSWORD,
  RMQ_VHOST: process.env.REACT_APP_RMQ_VHOST,
  RMQ_MAP_QUEUE: process.env.REACT_APP_RMQ_MAP_QUEUE,
  RMQ_TABLE_QUEUE: process.env.REACT_APP_RMQ_RECENTEVENTS_QUEUE,
  GW_ENDPOINT: process.env.REACT_APP_GW_ENDPOINT,
  INVENTORY_ENDPOINT: process.env.REACT_APP_INVENTORY_ENDPOINT,
  CONTROL_ENDPOINT: process.env.REACT_APP_CONTROL_ENDPOINT,
};

export default config;
