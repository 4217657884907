import styled from "styled-components";
import { FaSearch } from "react-icons/fa";

const StyledHeader = styled.header`
  display: flex;
  justify-content: flex-end;
  background-color: #ffffff;
  padding: 0.5rem 2.5rem;
  border-bottom: 0.5px solid #e6e6e6;
`;

const StyledSearchBar = styled.div`
  display: flex;
  align-items: flex-start;
  background: var(--color-grey-border-light);
  border-radius: 10px;
  width: 20%;
  height: 1rem;
  padding: 0.45rem;
  box-shadow: 0.2px #ddd;
`;

const StyledInput = styled.input`
  flex-grow: 1;
  align-self: center;
  border: none;
  background-color: transparent;
  font-size: 0.9rem;
  font-family: var(--font-family);
  margin-left: 0.75rem;
  outline: none;
  height: 100%;
  width: 100%;
`;

const StyledIcon = styled.div`
  color: var(--color-black);
  padding-left: 0.2rem;
  align-self: center;
  height: 1.1rem;

  &:hover {
    color: var(--color-green-border);
  }
`;

function Header() {
  return (
    <StyledHeader>
      <StyledSearchBar>
        <StyledIcon>
          <FaSearch />
        </StyledIcon>
        <StyledInput type="text" placeholder="Type to search..." />
      </StyledSearchBar>
    </StyledHeader>
  );
}

export default Header;
